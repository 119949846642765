import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { CircularProgress, Box } from '@mui/material';
import DistributionService from '../services/distribution/DistributionService';
import PersonService from '../services/person/PersonService';
import SlideDrawer from '../components/slideDrawer/SlideDrawer';
import ComposeEditor from '../components/compose/ComposeEditor';
import type { Anchor } from '../models/Anchor';
import MainSection from '../components/common/MainSection';
import DrawerTabs from '../components/compose/DrawerTabs';
import DocumentTreeService from '../services/documents/DocumentTreeService';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { setPageTitle } from '../state/slices/pageTitle';
import { DistributionMessagesKeys } from '../services/distribution/constants';
import type { Department, DepartmentResponse } from '../models/Department';
import type { Person } from '../models/Person';
import type { RootState } from '../state/store';
import progressStyles from '../theme/loadingProgress.module.scss';

const ComposeDrawerWidth: number = 500;

const loadData = (): void => {
  DistributionService.fetchBasicContactInfo();
  DistributionService.loadDistributionGroupAndMembers();
  DistributionService.fetchReplyToOptions();
  DistributionService.loadDistributionEmailTemplates();
  DistributionService.fetchEmailList();
  DocumentTreeService.initAllDocuments();
}

const anchor: Anchor = 'left';
export enum ComposePage {
  Compose = 'Compose'
}

interface Props {
  pageTitle: ComposePage
}

// A dummy component to showcase the use of React Router;
// however, we can use this as the Compose page
const Compose: FC<Props> = ({ pageTitle }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle])

  // Whether Slide Drawer is open or not
  const [open, setOpen] = useState<boolean>(true);
  // the varaible holding what current tab is open
  const [tab, setTab] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  // handling the tab change event
  const handleTabChange = (event: React.SyntheticEvent, newTab: number): void => {
    setTab(newTab);
  };

  // functions to handle opening and closing of drawer
  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!dataLoaded) {
      loadData();
      setDataLoaded(true);
    }
  }, []);

  const persons: Person[] = useAppSelector((state: RootState) => state.distribution.personList);
  const departments: DepartmentResponse[] = useAppSelector((state: RootState) => state.person.departments);

  const [departmentList, setDepartmentList] = useState<Department[]>([]);

  useEffect(() => {
    // timeNote: done three times - why? - is this also needed in drawerTabs?
    if (persons.length > 0 && departments.length > 0) {
      const departmentsWithPersons: Department[] = PersonService.addPersonToDepartments(departments, persons);
      setDepartmentList(departmentsWithPersons);
      setIsLoading(false);
    }
  }, [persons, departments])

  return (
      <Box sx={{ display: 'flex', width: '100%' }}>
        <SlideDrawer
          drawerWidth={ComposeDrawerWidth} open={open}
          anchor={anchor} toggleDrawer={toggleDrawer}
        >
          <DrawerTabs
            tab={tab}
            onChange={handleTabChange}
            departmentList={departmentList}
            areDepartmentsAvailable={true}
          />
        </SlideDrawer>
        <MainSection open={open} drawerwidth={ComposeDrawerWidth}>
          {
            (isLoading)
              ? <CircularProgress className={progressStyles.circularProgress} />
              : <ComposeEditor setListPanelOpen={setOpen}
                  pageTitle={ComposePage.Compose}
                  messageKey={DistributionMessagesKeys.Compose}
                  departmentList={departmentList}
                  toggleDrawer={toggleDrawer}
                />
          }
        </MainSection>
      </Box>
  )
}

export default Compose;
