import type { ReactElement } from 'react';
import UtilityService from '../UtilityService';
import { addExtraEmail, setIsSendingMessage } from '../../state/slices/DistributionReducer';
import type { ExtraEmail, ExtraEmailJSON, SelectedMember, SelectedGroup, EmailAndName } from '../../models/DistributionList';
import type { DistributionEmailTemplate } from '../../models/DistributionEmailTemplate';
import type { Draft } from '../../models/Draft';
import { setDraft, setSelectedExtraEmails, setMessageToSend, resetDraft } from '../../state/slices/DraftReducer';
import { TemplateDraft, DistributionDraft, OriginalMsgId } from '../draft/constants';
import type { DistributionMessage } from '../../models/DistributionMessage';
import type { Message } from '../../models/Message';
import { MessageStatus } from '../../models/Message';
import DistributionService from '../../services/distribution/DistributionService';
import PersonService from '../../services/person/PersonService';
import type { Person, PersonEmails } from '../../models/Person';
import type { TargetContact } from '../../models/Contacts';
import ModalService from '../ModalService';
import { NoSubjectModal } from '../../components/modals/NoSubject';
import { NoRecipientsModal } from '../../components/modals/NoRecipients';
import { InvalidRecipientsModal } from '../../components/modals/InvalidRecipients/constants';
import { NoAttachmentsModal } from '../../components/modals/NoAttachment/constants';
import { AttachmentErrorModal } from '../../components/modals/AttachmentError/constants';
import { RemoveVariablesModal } from '../../components/modals/RemoveVariables/constants';
import { IncludeAgentsModal } from '../../components/modals/IncludeAgents/constants';
import { InactiveContactsModal } from '../../components/modals/InactiveContacts/constants';
import { ConfirmModal } from '../../components/modals/ConfirmSend';
import { SendingFilesErrorModal } from '../../components/modals/SendingFilesError/constants';
import { MissingFilesModal } from '../../components/modals/MissingFiles/constants';
import { TextSMSModal } from '../../components/modals/TextSMS/constants';
import DocumentService from '../documents/DocumentService';
import type { FileAttachment } from '../../models/Document';
import type { LinkOptionsStatus, LinkAccessOptions } from '../../models/LinkOptions';
import type { WSResponse } from '../../models/WSResponse';
import { MaxAttachmentsSize } from '../../components/fileUpload/FileUpload';
import { Categories } from '../../models/Contacts';
import { DistributionMessagesKeys } from '../distribution/constants';
import { store } from '../../state/store';
import { setContactsToEdit } from '../../state/slices/Contacts';
import UploadService from '../UploadService';
import {
  SubjectRegex, EmailRegex,
  DepartmentCastId, SelectedTags, NoSubject, ReactQuillClassMap,
  ParagraphTag, DivTag
} from './constants';
import moment from 'moment';
import parse from 'html-react-parser';
import type { DOMNode } from 'html-react-parser';
import { renderToStaticMarkup } from 'react-dom/server';

const { dispatch } = store;

interface IMessageService {
  templateToDraft: (template: DistributionEmailTemplate, msgId: string) => void
  distributionToDraft: (distributionMessage: DistributionMessage, messageKey: DistributionMessagesKeys) => void
  copyRecipients: (draft: Draft) => void
  forwardSentMessage: (draft: Draft) => void
  restoreDraft: (draft: Draft) => void
  isFieldOverwriteValidated: (draft: Draft) => boolean
  loadExtraEmails: (messageKey: DistributionMessagesKeys) => void
  loadSubject: (subject: string) => string
  loadReplyTo: (replyTo: string) => string
  loadUnsignedMessage: (message: string) => string
  checkAttachmentSize: (status: MessageStatus, uploadFiles: FileAttachment[]) => Promise<boolean>
  areAllFieldsEmpty: (getValues: any, messageToSend: string) => boolean
  buildMessage: (status: MessageStatus, getValues: any, messageToSend: string, scheduledDateStamp: number | null) => Promise<void>
  buildTestMessage: (getValues: any, contentValue: string) => Promise<void>
  atLeastOneWMFile: (files: FileAttachment[]) => boolean
  getRawSubject: (subject: string) => string
  hasSubject: (status: MessageStatus, subject: string) => Promise<boolean>
  hasFiles: (status: MessageStatus, files: FileAttachment[]) => Promise<boolean>
  confirmMessage: (status: MessageStatus, groupList: SelectedGroup[], groupDepartment: SelectedGroup[]) => Promise<boolean>
  allFilesExist: (validatedFiles: FileAttachment[]) => Promise<boolean>
  emailVariablesOk: (selectedEmails: string[], unsignedMessage: string) => Promise<boolean>
  getUnknownRecipientsEmails: (selectedEmails: string[]) => string[]
  showTestSmsModal: (contacts: Person[]) => void
  insertInlineStyles: (unsignedMessage: string) => string
}

class MessageService implements IMessageService {
  /**
   * handling the changing of a template to a suitable draft model
   * @param template {DistributionEmailTemplate}
   * @returns draft {Draft}
   */
  templateToDraft (template: DistributionEmailTemplate, msgId: string): void {
    const draft: Draft = {
      id: msgId,
      restoreRecipients: template.isRecipientsIncluded,
      restoreContent: true,
      type: TemplateDraft
    };
    if (template.templateName) {
      draft.templateName = template.templateName;
    }
    if (template.isEmailBodyIncluded) {
      draft.unsignedMessage = template.emailBody;
    }
    if (template.isSMSIncluded) {
      draft.smsmessage = template.smsMessage;
    }
    if (template.isSubjectIncluded) {
      draft.subject = template.subject;
    }
    if (template.signature !== null && template.signature !== undefined) {
      draft.signature = template.signature;
    }
    if (template.replyTo !== null && template.replyTo !== undefined) {
      draft.replyTo = template.replyTo;
    }
    if (template.isRecipientsIncluded) {
      draft.distributionListId = template.distributionListId;
      draft.departmentId = template.departmentId;
      draft.extraEmailsForSelect = template.extraEmailsForSelect;
      draft.extraListMembersInputVal = template.extraListMembersInputVal;
    }
    draft.uploadedFilesJSON = template.attachmentJSON;
    dispatch(setDraft({ draft, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * handling the changing of a distribution message to a suitable draft model
   * @param distributionMessage {DistributionMessage}
   * @returns draft {Draft}
   */
  distributionToDraft (distributionMessage: DistributionMessage, messageKey: DistributionMessagesKeys): void {
    const draft: Draft = {
      id: distributionMessage.id ?? OriginalMsgId,
      restoreRecipients: Boolean(distributionMessage.recipientListJSON),
      restoreContent: true,
      type: DistributionDraft
    };
    draft.unsignedMessage = distributionMessage.unsignedMessage;
    draft.smsmessage = distributionMessage.smsmessage;
    draft.subject = distributionMessage.subject;
    draft.signature = distributionMessage.signature;
    draft.replyTo = distributionMessage.replyToEmail;
    draft.uploadedFilesJSON = distributionMessage.uploadedFilesJSON;
    if (distributionMessage.recipientListJSON) {
      draft.distributionListId = distributionMessage.distributionListId;
      draft.departmentId = distributionMessage.departmentId;
      draft.extraEmailsForSelect = distributionMessage.extraEmailsForSelect;
      draft.extraListMembersInputVal = distributionMessage.extraListMembersInputVal;
    }
    dispatch(setDraft({ draft, key: messageKey }));
  }

  /**
   * copy recipients from sent message to compose
   */
  copyRecipients (draft: Draft): void {
    dispatch(resetDraft({ key: DistributionMessagesKeys.Compose }));
    draft = {
      ...draft,
      id: OriginalMsgId,
      restoreRecipients: true,
      unsignedMessage: '',
      smsmessage: '',
      subject: '',
      signature: '',
      uploadedFilesJSON: ''
    };
    UploadService.clearUploadedFiles();
    dispatch(setDraft({ key: DistributionMessagesKeys.Compose, draft }));
  }

  /**
   * forward sent message to compose
   */
  forwardSentMessage (draft: Draft): void {
    dispatch(resetDraft({ key: DistributionMessagesKeys.Compose }));
    draft = { ...draft, restoreRecipients: false };
    dispatch(setDraft({ key: DistributionMessagesKeys.Compose, draft }));
    UploadService.clearUploadedFiles();
    UploadService.addMultipleFiles(draft.uploadedFilesJSON);
  }

  /**
   * restore draft function to restore a draft message in redux to compose
   */
  restoreDraft (draft: Draft): void {
    dispatch(resetDraft({ key: DistributionMessagesKeys.Compose }));
    draft = { ...draft, restoreRecipients: true };
    dispatch(setDraft({ key: DistributionMessagesKeys.Compose, draft }));
    UploadService.clearUploadedFiles();
    UploadService.addMultipleFiles(draft.uploadedFilesJSON);
  }

  /**
   * check to see if the field in the draft has been overwritten
   * @param draft
   */
  isFieldOverwriteValidated (draft: Draft): boolean {
    return true;
  }

  /**
   * get extra emails from draft
   * @param draft
   */
  loadExtraEmails (messageKey: DistributionMessagesKeys): void {
    const selectedExtraEmails: string[] = store.getState().draft.drafts[messageKey].selectedExtraEmails;
    const draft: Draft = store.getState().draft.drafts[messageKey].draft;
    let newSelectedExtraEmails: string[] = [];
    if (draft.extraEmailsJSON !== undefined && draft.extraEmailsJSON !== null && typeof draft.extraEmailsJSON === 'string') {
      const extraEmailsJSON = JSON.parse(draft.extraEmailsJSON);
      extraEmailsJSON.forEach((extraEmail: ExtraEmailJSON) => {
        if (extraEmail?.selectedEmail !== '') {
          const validEmail: boolean = manageExtraEmail(extraEmail.selectedEmail, extraEmail.primaryEmail);
          if (validEmail) {
            if (draft.type === TemplateDraft) {
              newSelectedExtraEmails = selectedExtraEmails.filter((selectedEmail: string) => selectedEmail !== extraEmail.primaryEmail);
              newSelectedExtraEmails = [...newSelectedExtraEmails, extraEmail.primaryEmail];
            } else {
              newSelectedExtraEmails.push(extraEmail.primaryEmail);
            }
          }
        }
      });
    } else if (draft.extraEmailsForSelect !== undefined && draft.extraEmailsForSelect !== null) {
      draft.extraEmailsForSelect.split(',').forEach((email: string) => {
        if (email !== undefined && email !== null && email !== '-') {
          const validEmail: boolean = manageExtraEmail(email, email);
          if (validEmail) {
            if (draft.type === TemplateDraft) {
              newSelectedExtraEmails = selectedExtraEmails.filter((selectedEmail: string) => selectedEmail !== email);
              newSelectedExtraEmails = [...newSelectedExtraEmails, email];
            } else {
              newSelectedExtraEmails.push(email);
            }
          }
        }
      });
    }
    dispatch(setSelectedExtraEmails({ selectedExtraEmails: newSelectedExtraEmails, key: messageKey }));
  }

  /**
   * get subject for draft
   * @param subject
   */
  loadSubject (subject: string): string {
    const draftSubject = this.getRawSubject(subject);
    return draftSubject;
  }

  /**
   * get loadReplyTo to send to backend
   * @param replyTo
   */
  loadReplyTo (replyTo: string): string {
    return replyTo;
  }

  /**
   * get unsigned message
   * @param message
   */
  loadUnsignedMessage (message: string): string {
    return message
  }

  /**
   * check if attachment size is too large to send to backend
   * @param uploadFiles
   */
  async checkAttachmentSize (status: MessageStatus, uploadFiles: FileAttachment[]): Promise<boolean> {
    let sizeOk: boolean = true;
    if (status === MessageStatus.DRAFT || findAttachmentsSize(uploadFiles) <= MaxAttachmentsSize) {
      return sizeOk;
    }
    setSendingMessage(false);
    sizeOk = await new Promise((resolve) => {
      ModalService.openCustomModal(
        AttachmentErrorModal,
        {
          heading: 'compose.attachmentError.heading',
          content: 'compose.attachmentError.content',
          confirmButton: 'action.ok',
          contentVariable: { '{{size}}': String(MaxAttachmentsSize) },
          callback: () => resolve(false)
        }
      );
    })
    return sizeOk;
  }

  areAllFieldsEmpty (getValues: any, messageToSend: string): boolean {
    const { selectedExtraEmails, groupDepartment, groupList } = store.getState().draft.drafts[DistributionMessagesKeys.Compose];
    const files: FileAttachment[] = store.getState().uploadFiles.files;
    // this is because when the editor is cleared it keeps these tags. This makes sure the unsignedMessage is empty after a clear
    const clearedMessageWithTags: string = '<p></p>';
    const unsignedMessage: string = messageToSend.replace(clearedMessageWithTags, '');
    return !unsignedMessage &&
      !getValues('subject') &&
      !getValues('smsmessage') &&
      selectedExtraEmails.length === 0 &&
      groupDepartment.length === 0 &&
      groupList.length === 0 &&
      files.length === 0;
  }

  /**
   * build message object to send a message
   * @param status - status of message type to be sent
   * @param getValues - get values from compose form
   */
  async buildMessage (status: MessageStatus, getValues: any, messageToSend: string, scheduledDateStamp: number | null): Promise<void> {
    const groupList: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupList;
    const groupDepartment: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupDepartment;
    const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;
    const files: FileAttachment[] = store.getState().uploadFiles.files;
    const affectedGroupMembers: SelectedMember[] = DistributionService.findAllAffectedDistGroups(groupList, groupDepartment);
    const linkOptions: LinkOptionsStatus = store.getState().draft.linkOptions;
    if (affectedGroupMembers.length === 0 && selectedExtraEmails.length === 0 && status === MessageStatus.READY) {
      handleNoRecipients();
      return;
    }

    setSendingMessage(true);

    let signatureContent: string = '';
    const result: WSResponse | null = await DistributionService.fetchSignature();
    if (result?.responseMessage) {
      signatureContent = result.responseMessage;
    }
    const messageContent: string = messageToSend;
    // messageContent = this.insertInlineStyles(messageContent);
    const subject: string = getValues('subject').trim();
    if (!await areRecipientsOk(status, affectedGroupMembers)) {
      return;
    }

    if (!await this.hasSubject(status, subject)) {
      return;
    }

    if (!await this.hasFiles(status, files)) {
      return;
    }

    if (!await this.checkAttachmentSize(status, files)) {
      return;
    }

    const departmentId: string = DistributionService.fetchCompleteGroups([], groupDepartment);
    // fetch partial list emails
    let partialMembers: string = '-';
    let extraListMembersInputVal: string = '-';

    const partialGroupMembersEmails: string[] = DistributionService.fetchPartialGroupsEmails(groupList, groupDepartment);
    if (partialGroupMembersEmails.length > 0) {
      partialMembers = partialGroupMembersEmails.join(',');
    }

    const partialGroupMembersIds: string[] = DistributionService.fetchPartialGroupsIds(groupList, groupDepartment);
    if (partialGroupMembersIds.length > 0) {
      extraListMembersInputVal = partialGroupMembersIds.join(',');
    }

    let extraEmailString: string = selectedExtraEmails.join(',');
    if (partialMembers.length > 0 && partialMembers !== '-') {
      extraEmailString = [extraEmailString, partialMembers].join(',');
    }
    extraEmailString = (extraEmailString.trim().length === 0) ? '-' : extraEmailString;

    const extraEmailsJSON: string = getExtraEmailsJSON(partialGroupMembersEmails, selectedExtraEmails);

    let includedAgentsJSON = JSON.stringify([]);
    let includeAgent = false;
    const agentsToInclude: PersonEmails[] = await shouldIncludeAgent(status, departmentId, affectedGroupMembers);
    if (agentsToInclude.length > 0) {
      includeAgent = true;
      includedAgentsJSON = JSON.stringify(agentsToInclude);
    }

    const excludedContacts: string[] = (status === MessageStatus.DRAFT) ? [] : await excludeUnavailableContacts(affectedGroupMembers);

    let extraEmailsForSelect = '-';
    if (selectedExtraEmails.length > 0) {
      extraEmailsForSelect = selectedExtraEmails.join(',');
    }

    // finish building message - confirm & send?
    if (!await this.confirmMessage(status, groupList, groupDepartment)) {
      return;
    }

    const distributionListId: string = DistributionService.fetchCompleteGroups(groupList, []);
    let fullMessage: string = messageContent + '<br/><br/>' + signatureContent;
    if (fullMessage.trim().length === 0) fullMessage = '-';
    let smsmessage: string = getValues('smsmessage');
    if (smsmessage.trim().length === 0) smsmessage = '-';

    const validatedFilesToSend: FileAttachment[] = [];
    if (files.length > 0) {
      files.forEach((file: FileAttachment) => {
        if (isValidFile(file)) {
          validatedFilesToSend.push(file);
          if (file.size === 0) {
            console.error('Missing file size. It\'s not mandatory yet but should always be present.');
          }
        } else {
          console.error('Cannot send file. Missing or invalid properties.');
          handleInvalidFile(file.fileName);
        }
      })
    }
    const uploadedFilesJSON: string = JSON.stringify(validatedFilesToSend);

    if (status !== MessageStatus.DRAFT) {
      if (!await this.allFilesExist(validatedFilesToSend)) {
        return;
      }
      setSendingMessage(true);
    }

    const areAttachmentsLinks: boolean = getValues('sendAsLinkChk') ?? false;
    let linkValidityTime: number = -1;
    if (linkOptions.linkValidityTime > 0) {
      linkValidityTime = linkOptions.durationHours + linkOptions.durationDays;
    }
    const linkAccessType: LinkAccessOptions = linkOptions.linkAccessType;
    const isDeepLink: boolean = linkOptions.isDeepLink;

    // build message
    const message: Message = {
      CGSESSIONID: '-', // TODO: get session Id
      tags: SelectedTags.join(' '),
      includeAgent,
      includedAgentsJSON,
      timeSaved: scheduledDateStamp ?? moment().valueOf(),
      distributionListNotify: '-',
      distributionListId,
      departmentId,
      subject: (subject !== null && subject !== undefined && subject !== '') ? subject : NoSubject,
      watermark: this.atLeastOneWMFile(validatedFilesToSend) ? 'true' : 'false', // atLeastOneWMFile
      message: fullMessage,
      unsignedMessage: messageContent,
      signature: signatureContent,
      replyToEmail: getValues('replyTo'),
      smsmessage,
      extraEmailsJSON,
      uploadedFilesJSON,
      areAttachmentsLinks,
      excludedContactsEmails: excludedContacts.join(','),
      extraemail: extraEmailString,
      extraEmailsForSelect,
      extraListMembersInputVal,
      linkValidityTime,
      linkAccessType,
      isdeeplink: isDeepLink,
      emailType: 0,
      status,
      id: (status === MessageStatus.DRAFT) ? getValues('msgId') : '-'
    }
    dispatch(setMessageToSend({ message }));
  }

  /**
   * build message object to send a test message
   * @param getValues - get values from compose form
   * @return Message Object or null
   */
  async buildTestMessage (getValues: any, contentValue: string): Promise<void> {
    const files: FileAttachment[] = store.getState().uploadFiles.files;
    const linkOptions: LinkOptionsStatus = store.getState().draft.linkOptions;
    const subject: string = getValues('subject') ?? '';
    const unsignedMessage: string = contentValue;
    const signature: string = getValues('signature') ?? '';
    const replyToEmail: string = getValues('replyTo') ?? '';
    const smsmessage: string = getValues('smsmessage') ?? '';

    const validatedFiles: FileAttachment[] = [];
    if (files.length > 0) {
      files.forEach((file: FileAttachment) => {
        if (isValidFile(file)) {
          validatedFiles.push(file);
          if (file.size === 0) {
            console.error('Missing file size. It\'s not mandatory yet but should always be present.');
          }
        } else {
          console.error('Cannot send file. Missing or invalid properties.');
          handleInvalidFile(file.fileName);
        }
      })
    }
    const uploadedFilesJSON: string = JSON.stringify(validatedFiles);

    if (!await this.allFilesExist(validatedFiles)) {
      return;
    }

    const areAttachmentsLinks: boolean = getValues('sendAsLinkChk');
    let linkValidityTime: number = -1;
    if (linkOptions.linkValidityTime > 0) {
      linkValidityTime = linkOptions.durationHours + linkOptions.durationDays;
    }
    const linkAccessType: LinkAccessOptions = linkOptions.linkAccessType;
    const isDeepLink: boolean = linkOptions.isDeepLink;

    const message: Message = {
      distributionListId: '-',
      departmentId: '-',
      distributionListNotify: '-',
      extraemail: '-',
      subject: subject ?? 'No Subject',
      watermark: this.atLeastOneWMFile(validatedFiles) ? 'true' : 'false',
      message: (unsignedMessage.trim().length === 0 && signature.trim().length === 0)
        ? '-'
        : `${unsignedMessage}<br/><br/>${signature}`,
      unsignedMessage,
      signature,
      replyToEmail,
      smsmessage: (smsmessage.trim().length === 0) ? '-' : smsmessage,
      uploadedFilesJSON,
      areAttachmentsLinks,
      linkValidityTime,
      linkAccessType,
      isdeeplink: isDeepLink
    };
    dispatch(setMessageToSend({ message }));
  }

  /**
   * check if any file has watermark present
   */
  atLeastOneWMFile (files: FileAttachment[]): boolean {
    return files.length > 0 && files.some((file: FileAttachment) => file.isWatermarked === '1')
  }

  /**
   * check if message suject exists
   * @param subject
   */
  getRawSubject (subject: string): string {
    const match = subject.match(SubjectRegex);
    return (match === null || match.index !== 0) ? subject : '';
  }

  async hasSubject (status: MessageStatus, subject: string): Promise<boolean> {
    let hasSubject: boolean = true;
    if (status !== MessageStatus.DRAFT) {
      const hasSubjectLine: boolean = (subject !== null && subject !== undefined && subject !== '');
      if (!hasSubjectLine) {
        setSendingMessage(false);
        hasSubject = await new Promise((resolve) => {
          ModalService.openCustomModal(
            NoSubjectModal,
            {
              heading: 'compose.form.message.noSubject',
              content: 'compose.form.message.noSubjectContent',
              confirmButton: 'action.proceed',
              callback: () => {
                setSendingMessage(true);
                resolve(true)
              },
              cancel: () => resolve(false)
            }
          );
        })
      }
    }
    return hasSubject;
  }

  async hasFiles (status: MessageStatus, files: FileAttachment[]): Promise<boolean> {
    let hasFiles: boolean = true;
    if (status !== MessageStatus.DRAFT) {
      const hasAttachments = files.length > 0;
      if (!hasAttachments) {
        setSendingMessage(false);
        hasFiles = await new Promise((resolve) => {
          ModalService.openCustomModal(
            NoAttachmentsModal,
            {
              heading: 'compose.noAttachment.heading',
              content: 'compose.noAttachment.content',
              confirmButton: 'action.proceed',
              callback: () => {
                setSendingMessage(true);
                resolve(true)
              },
              cancel: () => resolve(false)
            }
          );
        })
      }
    }
    return hasFiles;
  }

  async confirmMessage (status: MessageStatus, groupList: SelectedGroup[], groupDepartment: SelectedGroup[]): Promise<boolean> {
    let confirm = false;
    if (status === MessageStatus.READY) {
      const allAffectedDistGroups: SelectedGroup[] = [
        ...groupList,
        ...groupDepartment
      ];
      const skipGroupWarning: boolean = allAffectedDistGroups.length === 0;
      setSendingMessage(false);
      confirm = await new Promise((resolve) => {
        ModalService.openCustomModal(
          ConfirmModal,
          {
            heading: 'compose.form.message.confirm',
            content: (!skipGroupWarning) ? 'compose.form.message.confirmContent' : '',
            contentVariable: (!skipGroupWarning)
              ? { '{{groups}}': allAffectedDistGroups.map((group: SelectedGroup) => group.name).join(', ') }
              : {},
            confirmButton: 'action.confirm',
            callback: () => {
              setSendingMessage(true);
              resolve(true)
            },
            cancel: () => resolve(false)
          }
        );
      })
    } else if (status === MessageStatus.DRAFT) {
      confirm = true;
    }
    return confirm;
  }

  async allFilesExist (validatedFiles: FileAttachment[]): Promise<boolean> {
    let doAllFilesExist = true;
    if (Array.isArray(validatedFiles) && validatedFiles.length > 0) {
      const invalidFiles = await DocumentService.validateFilesExistence(validatedFiles);
      doAllFilesExist = invalidFiles.length === 0;
      if (!doAllFilesExist) {
        const fileNames: string[] = validatedFiles.filter(
          (file: FileAttachment) => invalidFiles.includes(file.id)
        ).map((file: FileAttachment) => file.fileName);
        setSendingMessage(false);
        doAllFilesExist = await new Promise((resolve) => {
          ModalService.openCustomModal(
            MissingFilesModal,
            {
              heading: 'compose.missingFiles.heading',
              content: 'compose.missingFiles.content',
              contentVariable: { '{{name}}': fileNames.join(', ') },
              confirmButton: 'action.ok',
              callback: () => resolve(false)
            }
          );
        });
      }
    }
    return doAllFilesExist;
  }

  /**
   * NOTE: This is currently not called as may be confusing for users
   * BUT: the code will stay here in case it wants to be added in again
   * Determines whether or not all inserted email variables, if any, can
   * be used on all selected recipients. Variables cannot be used on recipients
   * that only have an email address and are not mapped to Croogloo contacts.
   * These recipients would see empty Strings instead of personalized content.
   */
  async emailVariablesOk (selectedEmails: string[], unsignedMessage: string): Promise<boolean> {
    let result: boolean = true;
    const unknownEmails: string[] = this.getUnknownRecipientsEmails(selectedEmails);
    const areEmailVariables: boolean = EmailRegex.test(unsignedMessage);
    if (areEmailVariables && unknownEmails.length > 0) {
      setSendingMessage(false);
      result = await new Promise((resolve) => {
        ModalService.openCustomModal(
          RemoveVariablesModal,
          {
            heading: 'compose.removeVariables.heading',
            content: (unknownEmails.length === 1) ? 'compose.removeVariables.contentOne' : 'compose.removeVariables.contentOther',
            confirmButton: 'action.proceed',
            contentVariable: { '{{count}}': String(unknownEmails.length) },
            callback: () => {
              setSendingMessage(true);
              resolve(true)
            },
            cancel: () => resolve(false)
          }
        );
      })
    }
    return result;
  }

  getUnknownRecipientsEmails (selectedEmails: string[]): string[] {
    const existingEmails: ExtraEmail[] = store.getState().distribution.extraEmails;
    const cachedEmails: string[] = store.getState().distribution.cachedExtraEmails;
    const selectedExtraEmails: ExtraEmail[] = existingEmails.filter(
      (email: ExtraEmail) => selectedEmails.includes(email.primaryEmail)
    );
    let unknownEmails: string[] = selectedExtraEmails.filter(
      (email: ExtraEmail) => email.email === email.label && email.email === email.primaryEmail
    ).map((email: ExtraEmail) => email.primaryEmail);

    unknownEmails = [
      ...unknownEmails,
      ...cachedEmails.filter((email: string) => selectedEmails.includes(email))
    ];
    return unknownEmails;
  }

  showTestSmsModal (contacts: Person[]): void {
    dispatch(setContactsToEdit({ contacts }));
    ModalService.openCustomModal(
      TextSMSModal,
      {
        heading: 'compose.form.action.textSms'
      }
    );
  }

  /**
   * pick up all react quill classes in the message
   * Replace with in line styles
   * Again react quill makes this awkward, this is a quick fix for now
   * TODO: CFR-127
   * @param unsignedMessage
   */
  insertInlineStyles (unsignedMessage: string): string {
    const parsedNodes = parse(unsignedMessage, {
      replace: processNode
    });
    return renderToStaticMarkup(parsedNodes as ReactElement);
  }
}

const processNode = (node: DOMNode): DOMNode => {
  if (node.type === 'tag') {
    const classNames: string = node.attribs.class;
    const isParagraph: boolean = node.name?.toLowerCase() === ParagraphTag;
    if (isParagraph) {
      node.name = DivTag;
    }
    if (classNames) {
      const existingStyle = node.attribs.style || '';
      const classes: string[] = classNames.split(' ');
      const inlineStyles: string[] = classes.map(
        (className: string) => { return ReactQuillClassMap[className] ?? '' }
      ).filter((inline: string) => inline);
      const inlineAsString: string = inlineStyles.join(' ');
      const mergedStyles = (existingStyle) ? existingStyle + `; ${inlineAsString}` : inlineAsString;
      node.attribs.style = mergedStyles.trim();
    }
  }
  return node
}

const isValidFile = (file: FileAttachment): boolean => {
  let result = false;
  if (file.id && file.url && file.fileName && /0|1/.exec(file.isWatermarked)) {
    result = true;
  }
  return result;
}

const manageExtraEmail = (email: string, primaryEmail: string): boolean => {
  const existingEmails = store.getState().distribution.extraEmails;
  email = email.trim();
  if (email === undefined || email === null || email === '') { return false; }
  if (UtilityService.isInValidEmailAddress(email)) {
    console.error('Invalid email address: "' + email + '"');
    return false;
  }

  // if not existing email add to email list
  const emailFound: ExtraEmail | undefined = existingEmails.find((existingEmail: ExtraEmail) => existingEmail.primaryEmail === primaryEmail);
  if (emailFound === undefined) {
    dispatch(addExtraEmail({
      extraEmail: {
        email,
        label: email,
        primaryEmail
      }
    }));
  }
  return true;
}

/**
 * find the size of attachment files
 * @param uploadedFiles
 */
const findAttachmentsSize = (uploadedFiles: FileAttachment[]): number => {
  // Just using any type for now - replace with a file type when files implemented
  let totalSize: number = 0;
  uploadedFiles.forEach((file: FileAttachment) => {
    if (file.size !== null) {
      totalSize += file.size;
    }
  });
  if (totalSize > 0) {
    return Math.round(totalSize / 100000) / 10;
  }
  return totalSize;
}

/**
 * checks to see if all recipients for message are correctly formatted emails
 * @param status - status of message type to be sent
 * @param affectedGroupMembers - members from lists & departments to be sent the message
 */
const areRecipientsOk = async (status: MessageStatus, affectedGroupMembers: SelectedMember[]): Promise<boolean> => {
  let result: boolean = true;
  if (status !== MessageStatus.DRAFT) {
    const existingEmails: ExtraEmail[] = store.getState().distribution.extraEmails;
    const cachedEmails: string[] = store.getState().distribution.cachedExtraEmails;
    const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;

    const selectedExistingEmails: ExtraEmail[] = existingEmails.filter(
      (email: ExtraEmail) => selectedExtraEmails.includes(email.email)
    );

    // get emails & names to check valid email for all recipients
    let emailAndNames: EmailAndName[] = selectedExistingEmails.map((email: ExtraEmail) => ({
      email: email.primaryEmail,
      name: email.name ?? ''
    }));

    emailAndNames = [
      ...emailAndNames,
      ...cachedEmails.filter(
        (email: string) => selectedExtraEmails.includes(email)
      ).map((email: string) => ({
        email,
        name: ''
      }))
    ];

    emailAndNames = [
      ...emailAndNames,
      ...affectedGroupMembers.map((member: SelectedMember) => ({
        email: member.email,
        name: member.name
      }))
    ];

    // check if emails are invalid
    const invalidEmailsFormatted: string[] = UtilityService.checkValidEmailAddresses(emailAndNames);
    if (invalidEmailsFormatted.length > 0) {
      result = await handleInvalidRecipients();
    }
  }
  return result;
}

/**
 * check to see if agents should be included if casts are included in message
 * get the potential agents and dispatch them to redux to include them in the popup
 * @param departmentId
 * @param affectedGroupMembers
 */
const shouldIncludeAgent = async (status: MessageStatus, departmentId: string, affectedGroupMembers: SelectedMember[]): Promise<PersonEmails[]> => {
  if (status === MessageStatus.DRAFT) {
    return [];
  }
  let agentsToInclude: PersonEmails[] = [];
  const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;
  const primarySelectedEmails: string[] = getPrimaryEmailsFromEmails(selectedExtraEmails);
  const allRecipientEmails: string[] = [
    ...primarySelectedEmails,
    ...affectedGroupMembers.map((member: SelectedMember) => (member.email))
  ];
  const isCastDepartmentIncluded = departmentId.split(',').includes(DepartmentCastId);
  const persons: Person[] = store.getState().distribution.personList;
  const castMembers: Person[] = persons.filter((person: Person) => person.subcategory === Categories.Cast);
  const recipientCastEmails: string[] = castMembers.map((cast: Person) => cast.email)
    .filter((email: string) => allRecipientEmails.includes(email));
  if (isCastDepartmentIncluded || recipientCastEmails.length > 0) {
    const castIds: string[] = [];
    if (isCastDepartmentIncluded) {
      castIds.push(...persons.filter((person: Person) => person.departmentId === DepartmentCastId).map((person: Person) => person.name));
    }
    if (recipientCastEmails.length > 0) {
      for (const castId of castMembers
        .filter((cast: Person) => recipientCastEmails.includes(cast.email))
        .map((person: Person) => person.name)) {
        if (!castIds.includes(castId)) {
          castIds.push(castId);
        }
      }
    }

    const potentialAgentsToInclude: TargetContact[] = await PersonService.getPotentialAgentsToInclude(castIds, allRecipientEmails);
    if (potentialAgentsToInclude.length > 0) {
      setSendingMessage(false);
      const includeAgent: boolean = await new Promise((resolve) => {
        ModalService.openCustomModal(
          IncludeAgentsModal,
          {
            heading: 'compose.includeAgents.heading',
            content: (potentialAgentsToInclude.length === 1) ? 'compose.includeAgents.contentOne' : 'compose.includeAgents.contentOther',
            confirmButton: 'action.include',
            contentVariable: {
              '{{count}}': String(potentialAgentsToInclude.length),
              '{{titles}}': potentialAgentsToInclude.map((contact: TargetContact) => contact.title).join(', ')
            },
            callback: () => {
              setSendingMessage(true);
              resolve(true)
            },
            cancel: () => resolve(false)
          }
        );
      })
      if (includeAgent) {
        agentsToInclude = potentialAgentsToInclude.map((agent: TargetContact) => ({
          primaryEmail: agent.email,
          selectedEmail: null
        }))
      }
    }
  }
  return agentsToInclude;
}

const excludeUnavailableContacts = async (affectedGroupMembers: SelectedMember[]): Promise<string[]> => {
  let excludeUnavailableContacts: string[] = [];
  const formattedDate: string = moment().format('YYYY-MM-DD');
  const displayDate: string = moment().format('MMM, DD, YYYY');
  const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;
  const primarySelectedEmails: string[] = getPrimaryEmailsFromEmails(selectedExtraEmails);
  const persons: Person[] = store.getState().distribution.personList;
  const allRecipientEmails: string[] = [
    ...primarySelectedEmails,
    ...affectedGroupMembers.map((member: SelectedMember) => (member.email))
  ];
  const personIds: string[] = persons.filter(
    (person: Person) => allRecipientEmails.includes(person.email)
  ).map((person: Person) => person.name);
  const excludeContacts: TargetContact[] = await PersonService.fetchExclusionsByInactivity(personIds, formattedDate);
  if (excludeContacts.length > 0) {
    setSendingMessage(false);
    const exclude: boolean = await new Promise((resolve) => {
      ModalService.openCustomModal(
        InactiveContactsModal,
        {
          heading: 'compose.inactiveContacts.heading',
          content: 'compose.inactiveContacts.content',
          confirmButton: 'action.include',
          contentVariable: {
            '{{titles}}': excludeContacts.map((contact: TargetContact) => contact.title).join(', '),
            '{{sendDate}}': displayDate
          },
          callback: () => {
            setSendingMessage(true);
            resolve(true)
          },
          cancel: () => resolve(false)
        }
      );
    });
    if (!exclude) {
      excludeUnavailableContacts = [...excludeContacts.map((contact: TargetContact) => contact.email)];
    }
  }
  return excludeUnavailableContacts;
}

const getExtraEmailsJSON = (partialMembers: string[], selectedExtraEmails: string[]): string => {
  let extraEmails: PersonEmails[] = [];
  const existingEmails: ExtraEmail[] = store.getState().distribution.extraEmails;
  const cachedEmails: string[] = store.getState().distribution.cachedExtraEmails;

  if (selectedExtraEmails.length > 0) {
    extraEmails = existingEmails.filter(
      (email: ExtraEmail) => selectedExtraEmails.includes(email.primaryEmail)
    ).map((email: ExtraEmail) => ({
      primaryEmail: email.primaryEmail,
      selectedEmail: email.email
    }));

    extraEmails = [
      ...extraEmails,
      ...cachedEmails.filter(
        (email: string) => selectedExtraEmails.includes(email)
      ).map((email: string) => ({
        primaryEmail: email,
        selectedEmail: email
      }))
    ];
  }

  if (partialMembers.length > 0) {
    extraEmails = [
      ...extraEmails,
      ...partialMembers.map((email: string) => ({
        primaryEmail: email,
        selectedEmail: null
      }))
    ];
  }

  return JSON.stringify(extraEmails);
}

const handleNoRecipients = (): void => {
  ModalService.openCustomModal(
    NoRecipientsModal,
    {
      heading: 'compose.form.message.noRecipients',
      confirmButton: 'action.gotIt'
    }
  );
}

const handleInvalidFile = (fileName: string) => {
  setSendingMessage(false);
  ModalService.openCustomModal(
    SendingFilesErrorModal,
    {
      heading: 'common.labels.error',
      content: 'compose.form.message.sendingFilesError',
      confirmButton: 'action.ok',
      contentVariable: { '{{name}}': fileName }
    }
  );
}

const handleInvalidRecipients = async (): Promise<boolean> => {
  return await new Promise((resolve) => {
    setSendingMessage(false);
    ModalService.openCustomModal(
      InvalidRecipientsModal,
      {
        heading: 'compose.invalidRecipients.heading',
        confirmButton: 'action.proceed',
        callback: () => {
          setSendingMessage(true);
          resolve(true)
        },
        cancel: () => resolve(false)
      }
    );
  });
}

/**
 * set boolean in distribution store if building/message in compose
 * This displays the loading progress in the compose
 */
const setSendingMessage = (isSending: boolean): void => {
  dispatch(setIsSendingMessage({ isSendingMessage: isSending }));
}

const getPrimaryEmailsFromEmails = (selectedExtraEmails: string[]): string[] => {
  const existingEmails: ExtraEmail[] = store.getState().distribution.extraEmails;
  const selectedExistingEmails: ExtraEmail[] = existingEmails.filter((existingEmail: ExtraEmail) => selectedExtraEmails.includes(existingEmail.email));
  const primarySelectedEmails: string[] = selectedExistingEmails.map((email: ExtraEmail) => email.primaryEmail);
  const uniquePrimaryEmails: string[] = primarySelectedEmails.filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);
  return uniquePrimaryEmails;
}

const messageService: IMessageService = new MessageService();
export default messageService;
