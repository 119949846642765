
import { useCallback, useState } from 'react';
import {
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';

import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatListNumbered,
  FormatListBulleted,
  TextFormat,
  FontDownload,
  Image,
  Redo,
  Undo,
  OpenWith,
  FormatClear
} from '@mui/icons-material';
import { Editable, ReactEditor } from 'slate-react';
import type { RenderLeafProps, RenderElementProps } from 'slate-react';
import { Editor, Element as SlateElement, Transforms, Text, Range } from 'slate';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { HistoryEditor } from 'slate-history';

import ColorButton from './ColorButton';
import AlignButton from './AlignButton';
import LinkButton from './LinkButton';
import useStyles from './editorStyles';
import scrollStyles from '../../theme/sidebar.module.scss';
import type { CustomText, CustomElement } from '../../services/SlateToHtmlService';
import MarkButton from './MarkButton';
import type { MarkFormat } from './MarkButton';
import BlockButton from './BlockButton';

export interface ImageElement {
  type: 'image'
  url: string
  children: Array<{ text: string }>
}

export interface LinkElement {
  type: 'link'
  url: string
  children: Array<{ text: string }>
};

const MarksToRemove: string[] = ['bold', 'italic', 'underline', 'fontSize', 'fontFamily', 'color', 'backgroundColor'];
const ListTypes = ['numbered-list', 'bulleted-list'];

const Leaf = (props: RenderLeafProps) => {
  const leaf = props.leaf as CustomText;
  const style: React.CSSProperties = (leaf.style) ? { ...leaf.style } : {};
  if (leaf.fontFamily) {
    style.fontFamily = leaf.fontFamily;
  }
  if (leaf.fontSize) {
    style.fontSize = leaf.fontSize;
  }
  if (leaf.color) {
    style.color = leaf.color;
  }
  if (leaf.backgroundColor) {
    style.backgroundColor = leaf.backgroundColor;
  }
  return (
    <span
      {...props.attributes}
      style={{
        fontWeight: leaf.bold ? 'bold' : 'normal',
        fontStyle: leaf.italic ? 'italic' : 'normal',
        textDecoration: leaf.underline ? 'underline' : 'none',
        ...style
      }}
      id={leaf.id}
      className={leaf.className}
    >
      {props.children}
    </span>
  );
};

const Element = (props: RenderElementProps) => {
  const element = props.element as CustomElement;
  const alignment = element.align ?? 'left';
  switch (element.type) {
    case 'block-quote': {
      return (
        <blockquote {...props.attributes} style={{ textAlign: alignment }}>
          {props.children}
        </blockquote>
      );
    }
    case 'bulleted-list':
      return (
        <ul {...props.attributes} style={{ textAlign: alignment }}>
          {props.children}
        </ul>
      )
    case 'numbered-list':
      return (
        <ol {...props.attributes} style={{ textAlign: alignment }}>
          {props.children}
        </ol>
      )
    case 'list-item':
      return (
        <li {...props.attributes} style={{ textAlign: alignment }}>
        {props.children}
        </li>
      )
    case 'image':
      return (
        <div {...props.attributes}>
          <img src={(element as ImageElement).url} alt="" style={{ maxWidth: '100%' }} />
          {props.children}
        </div>
      )
    case 'link':
      return (
        <a {...props.attributes} href={(element as LinkElement).url} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      )
    default:
      return (
        <p {...props.attributes} style={{ textAlign: alignment }}>
          {props.children}
        </p>
      )
  }
}

const isFontSizeActive = (editor: any, fontSize: string): boolean => {
  const [match] = Array.from(Editor.nodes(editor, {
    match: n => Text.isText(n) && (n as CustomText).fontSize === fontSize,
    mode: 'all'
  }));
  return !!match;
}

const isFontFamilyActive = (editor: any, fontFamily: string): boolean => {
  const [match] = Array.from(Editor.nodes(editor, {
    match: n => Text.isText(n) && (n as CustomText).fontFamily === fontFamily,
    mode: 'all'
  }));
  return !!match;
};

const isMarkActive = (editor: any, format: MarkFormat): boolean => {
  const marks = Editor.marks(editor) as CustomText;
  return marks ? marks[format] === true : false
}

const isBlockActive = (editor: any, format: string, blockType: 'type' | 'align'): boolean => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n as CustomElement)[blockType] === format
    })
  )
  return !!match;
}

const isLinkActive = (editor: any) => {
  const [link] = Array.from(Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as CustomElement).type === 'link'
  }));
  return !!link;
};

const insertImage = (editor: any, url: string): void => {
  const image: ImageElement = {
    type: 'image',
    url,
    children: [{ text: '' }]
  };
  Transforms.insertNodes(editor, image);
}

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as CustomElement).type === 'link'
  });
};

const insertLink = (editor: any, url: string): void => {
  if (!url) return;

  const { selection } = editor;
  if (selection) {
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link: LinkElement = {
      type: 'link',
      url,
      children: isCollapsed ? [{ text: url }] : []
    };
    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: 'end' });
    }
  }
}

const toggleMark = (editor: any, format: MarkFormat): void => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

const toggleAlignment = (editor: any, alignment: 'left' | 'center' | 'right'): void => {
  const [match] = Array.from(Editor.nodes(editor, {
    match: n =>
      SlateElement.isElement(n) && Editor.isBlock(editor, n)
  }));

  if (match) {
    Transforms.setNodes<CustomElement>(
      editor,
      { align: alignment },
      { match: n => SlateElement.isElement(n) && (Editor.isBlock(editor, n) || (n as CustomElement).type === 'ul' || (n as CustomElement).type === 'ol' || (n as CustomElement).type === 'li') }
    );
  }
}

const toggleBlock = (editor: any, format: string): void => {
  const isActive = isBlockActive(editor, format, 'type');
  const isList = ListTypes.includes(format);

  Transforms.setNodes<CustomElement>(
    editor,
    { type: isActive ? 'paragraph' : isList ? 'list-item' : format }
  );

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleColor = (editor: any, color: string | null, selection: Range | null): void => {
  const currentSelection: Range = selection ?? editor.selection;
  const isCollapsed = Range.isCollapsed(currentSelection);
  if (isCollapsed) {
    if (color) {
      Editor.addMark(editor, 'color', color);
    } else {
      Editor.removeMark(editor, 'color');
    }
  } else {
    if (color) {
      Transforms.setNodes<CustomElement>(
        editor,
        { color },
        { match: Text.isText, split: true }
      );
    }
  }
  ReactEditor.focus(editor);
}

const toggleBackgroundColor = (editor: any, color: string | null, selection: Range | null): void => {
  const currentSelection: Range = selection ?? editor.selection;
  const isCollapsed = Range.isCollapsed(currentSelection);
  if (isCollapsed) {
    if (color) {
      Editor.addMark(editor, 'backgroundColor', color);
    } else {
      Editor.removeMark(editor, 'backgroundColor');
    }
  } else {
    if (color) {
      Transforms.setNodes<CustomElement>(
        editor,
        { backgroundColor: color },
        { match: Text.isText, split: true }
      );
    }
  }
  ReactEditor.focus(editor);
}

interface Props {
  editor: any
  placeholder?: string
  toolbar: boolean
  isReadOnly: boolean
}

function TextEditor ({ editor, placeholder, toolbar, isReadOnly }: Props) {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const classes = useStyles();

  const [editorExpanded, setEditorExpanded] = useState<boolean>(false);
  const [currentSelection, setCurrentSelection] = useState<Range | null>(null);
  const [activeAlign, setActiveAlign] = useState<'left' | 'center' | 'right'>('left');

  const renderLeaf = useCallback((props: RenderLeafProps) => {
    return <Leaf {...props} />;
  }, []);

  const renderElement = useCallback((props: RenderElementProps) => {
    return <Element {...props} />;
  }, []);

  const onKeyDown = (event: React.KeyboardEvent): void => {
    if (!event.ctrlKey) {
      return;
    }

    event.preventDefault();

    switch (event.key) {
      case 'b': {
        toggleMark(editor, 'bold');
        return;
      }
      case 'i': {
        toggleMark(editor, 'italic');
        return;
      }
      case 'u': {
        toggleMark(editor, 'underline');
      }
    }
  }

  const handleFontChange = (event: SelectChangeEvent): void => {
    event.stopPropagation();
    const fontFamily: string = event.target.value;
    if (fontFamily) {
      const isActive = isFontFamilyActive(editor, fontFamily);
      Transforms.setNodes<CustomElement>(
        editor,
        { fontFamily: isActive ? undefined : fontFamily },
        { match: Text.isText, split: true }
      );
    }
  }

  const handleSizeChange = (event: SelectChangeEvent): void => {
    event.stopPropagation();
    const fontSize: string = event.target.value;
    if (fontSize) {
      const isActive = isFontSizeActive(editor, fontSize);
      Transforms.setNodes<CustomElement>(
        editor,
        { fontSize: isActive ? undefined : fontSize },
        { match: Text.isText, split: true }
      );
    }
  }

  const handleColorOpen = (): void => {
    setCurrentSelection(editor.selection);
  }

  const handleTextColorChange = (color: string): void => {
    toggleColor(editor, color, currentSelection);
    setCurrentSelection(null);
  }

  const handleBackgroundColorChange = (color: string): void => {
    toggleBackgroundColor(editor, color, currentSelection);
    setCurrentSelection(null);
  }

  const handleRedo = (): void => {
    if (editor.history.redos.length > 0) {
      HistoryEditor.redo(editor);
    }
  }

  const handleUndo = (): void => {
    if (editor.history.undos.length > 0) {
      HistoryEditor.undo(editor);
    }
  }

  const clearMarks = () => {
    for (const mark of MarksToRemove) {
      Editor.removeMark(editor, mark);
    }
  }

  const clearBlocks = (): void => {
    Transforms.setNodes<CustomElement>(
      editor,
      { type: 'paragraph', align: 'left' },
      { match: n => SlateElement.isElement(n), split: true }
    );
  }

  const clearAll = (): void => {
    clearMarks();
    clearBlocks();
  }

  const isAlignActive = (align: 'left' | 'center' | 'right'): boolean => {
    const active: boolean = isBlockActive(editor, align, 'align');
    return active;
  }

  const handleAlignSelect = (align: string): void => {
    setActiveAlign(align as 'left' | 'center' | 'right');
    toggleAlignment(editor, align as 'left' | 'center' | 'right');
  }

  const handleExpandEditor = (): void => {
    setEditorExpanded(!editorExpanded);
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imageUrl = reader.result as string;
        insertImage(editor, imageUrl);
      };
    }
  }

  const handleInsertLink = (url: string): void => {
    if (!url) return;
    insertLink(editor, url);
  }

  const checkIsLinkActive = (): boolean => {
    return isLinkActive(editor);
  }

  const handleUnwrapLink = (): void => {
    unwrapLink(editor);
  }

  const emptyIconComponent = () => {
    return null;
  }

  return (
    <Grid
     sx={{
       display: 'flex',
       flexDirection: 'column',
       width: '100%',
       height: '100%'
     }}
     className={`${(editorExpanded) ? classes.expanded : ''}`}
    >
      {(toolbar) && <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          borderTop: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <FormControl
          className={classes.formControl}
        >
          <InputLabel shrink={false}>F</InputLabel>
          <Select
            value={''}
            onChange={handleFontChange}
            IconComponent={emptyIconComponent}
            displayEmpty
            sx={{
              border: 'none'
            }}
          >
            <MenuItem value={'arial'}>{t('compose.editor.toolbar.font.arial')}</MenuItem>
            <MenuItem value={'Comic Sans MS'}>{t('compose.editor.toolbar.font.comicSans')}</MenuItem>
            <MenuItem value={'Courier New'}>{t('compose.editor.toolbar.font.courierNew')}</MenuItem>
            <MenuItem value={'georgia'}>{t('compose.editor.toolbar.font.georgia')}</MenuItem>
            <MenuItem value={'helvetica'}>{t('compose.editor.toolbar.font.helvetica')}</MenuItem>
            <MenuItem value={'lucida'}>{t('compose.editor.toolbar.font.lucida')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          className={classes.formControl}
        >
          <InputLabel shrink={false}>S</InputLabel>
          <Select
            value={''}
            onChange={handleSizeChange}
            IconComponent={emptyIconComponent}
            displayEmpty
            sx={{
              border: 'none'
            }}
          >
            <MenuItem value={'0.75em'}>{t('compose.editor.toolbar.fontSize.small')}</MenuItem>
            <MenuItem value={'1em'}>{t('compose.editor.toolbar.fontSize.medium')}</MenuItem>
            <MenuItem value={'1.5em'}>{t('compose.editor.toolbar.fontSize.large')}</MenuItem>
            <MenuItem value={'2.5em'}>{t('compose.editor.toolbar.fontSize.heading')}</MenuItem>
          </Select>
        </FormControl>
        <MarkButton format={'bold'} Icon={FormatBold} style={classes.button} toggleMark={toggleMark} />
        <MarkButton format={'italic'} Icon={FormatItalic} style={classes.button} toggleMark={toggleMark} />
        <MarkButton format={'underline'} Icon={FormatUnderlined} style={classes.button} toggleMark={toggleMark} />
        <BlockButton format={'numbered-list'} Icon={FormatListNumbered} style={classes.button} toggleBlock={toggleBlock} />
        <BlockButton format={'bulleted-list'} Icon={FormatListBulleted} style={classes.button} toggleBlock={toggleBlock} />
        <AlignButton handleAlignSelect={handleAlignSelect} isActive={isAlignActive} activeAlign={activeAlign} />
        <ColorButton Icon={TextFormat} handleColorSelect={handleTextColorChange} handleColorOpen={handleColorOpen}/>
        <ColorButton Icon={FontDownload} handleColorSelect={handleBackgroundColorChange} handleColorOpen={handleColorOpen} />
        <LinkButton checkIsLinkActive={checkIsLinkActive} handleLinkSelect={handleInsertLink} handleUnwrap={handleUnwrapLink} />
        <IconButton
          onMouseDown={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            document.getElementById('image-input')?.click();
          }}
          disableRipple
          className={classes.button}
        >
          <Image />
        </IconButton>
        <IconButton
          onMouseDown={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            clearAll();
          }}
          disableRipple
          className={classes.button}
        >
          <FormatClear />
        </IconButton>
        <IconButton
          onMouseDown={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            handleUndo();
          }}
          disableRipple
          className={classes.button}
        >
          <Undo />
        </IconButton>
        <IconButton
          onMouseDown={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            handleRedo();
          }}
          disableRipple
          className={classes.button}
        >
          <Redo />
        </IconButton>
        <IconButton
          onMouseDown={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            handleExpandEditor();
          }}
          disableRipple
          className={classes.button}
        >
          <OpenWith />
        </IconButton>
      </Grid>}
      <Editable
        style={{ height: '100%', margin: '15px', outline: 'none', maxHeight: '240px', overflowY: 'auto', marginRight: '2px' }}
        className={scrollStyles.scrollbar}
        renderLeaf={renderLeaf}
        renderElement={renderElement}
        placeholder={placeholder ? String(t(placeholder)) : ''}
        onKeyDown={onKeyDown}
        readOnly={isReadOnly}
      />
      <input
        id="image-input"
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </Grid>
  )
}

export default TextEditor;
